/* eslint-disable no-console */
import {
  AspectRatio,
  Avatar,
  Box,
  Button,
  Center,
  HStack,
  Image,
  InputGroup,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import useUserStore from '../../Hooks/Zustand/Store';
import { useTranslation } from 'react-i18next';
import NewFeatureDetailsModal from './Components/NewFeatureDetailsModal';
import { FcFolder, FcVideoFile } from 'react-icons/fc';
import { getUserByUid } from '../../Utils/getUser';
import ModalFilterDate from '../../Components/DatePicker/ModalFilterDate';
import {
  countDocumentsFirebase,
  getCollectionFirebase,
} from '../../Api/firebaseApi';

function NewFeaturePage() {
  const { t } = useTranslation();
  const globalState = useUserStore();
  const [data, setData] = useState({ data: [], totalDoc: 0, selectedData: {} });

  // filtering
  const itemsPerPage = 8;
  const [startIndex, setStartIndex] = useState(0);
  const limitValue = startIndex + itemsPerPage;
  const [filterDate, setFilterDate] = useState({
    startDate: moment().subtract(1, 'months').toDate(),
    endDate: moment().endOf('week').toDate(),
  });
  const handleDateRangeChange = (newStartDate, newEndDate) => {
    setFilterDate({
      startDate: moment(newStartDate).startOf('day').toDate(),
      endDate: moment(newEndDate).endOf('day').toDate(),
    });
  };

  // conditional session
  const viewFeature = useDisclosure();
  const toast = useToast();

  const getData = async (limitData) => {
    globalState.setIsLoading(true);
    try {
      const conditions = [
        {
          field: 'projectId',
          operator: '==',
          value: '8NCG4Qw0xVbNR6JCcJw1',
        },
        {
          field: 'column',
          operator: '==',
          value: 'done',
        },
        {
          field: 'label',
          operator: '==',
          value: 'completed',
        },
        {
          field: 'category',
          operator: '==',
          value: 'Feature',
        },
      ];

      if (filterDate) {
        conditions.push(
          {
            field: 'time_completed_at',
            operator: '>=',
            value: filterDate?.startDate,
          },
          {
            field: 'time_completed_at',
            operator: '<=',
            value: filterDate?.endDate,
          }
        );
      }
      const [res, totalDoc] = await Promise.all([
        getCollectionFirebase(
          'kanban',
          conditions,
          { field: 'time_completed_at', operator: 'desc' },
          limitData,
          null
        ),
        countDocumentsFirebase('kanban', conditions),
      ]);

      setData((prev) => ({ ...prev, data: res || [], totalDoc: totalDoc }));
    } catch (error) {
      console.log(error.message);
      toast({
        title: 'Oops!',
        description: error.message,
        isClosable: true,
        duration: 3000,
        status: 'error',
      });
    }
    globalState.setIsLoading(false);
  };

  useEffect(() => {
    if (globalState?.currentCompany) {
      getData(limitValue)
    }
  }, [globalState?.currentCompany]);

  const handleLoadMore = () => {
    setStartIndex((prevStartIndex) => {
      const newStartIndex =
        prevStartIndex === 0 ? 2 * itemsPerPage : prevStartIndex + itemsPerPage;
      getData(newStartIndex);
      return newStartIndex;
    });
  };

  const handleVideoFeature = (x) => {
    setData((prev) => ({ ...prev, selectedData: x }));
    viewFeature.onOpen();
  };

  return (
    <Stack p={[1, 1, 5]} spacing={10} minH="100vh">
      <HStack w={'full'} justifyContent={'space-between'} alignItems={'center'}>
        <BackButtons />
        <Text fontWeight={'bold'} fontSize="3xl">
          🚀 {t('newFeatureNotif')}
        </Text>
        <Spacer />
        <ModalFilterDate
          handleDateRangeChange={handleDateRangeChange}
          getData={() => {
            setStartIndex(0);
            getData(8);
          }}
        />
      </HStack>

      <Stack
        spacing={2}
        borderTopWidth={3}
        borderBottomWidth={3}
        borderColor="green.500"
        py={4}
        px={2}
        borderRadius="md"
        shadow="md"
      >
        <Stack gap={1}>
          {data?.data?.length > 0 ? (
            data?.data?.map((x, index) => {
              const typeFile =
                x?.files?.length > 0
                  ? x?.files[x?.files?.length - 1]?.type
                  : '';
              const link =
                x?.files?.length > 0
                  ? x?.files[x?.files?.length - 1]?.link
                  : '';
              const mov =
                x?.files?.length > 0 && typeFile === 'video'
                  ? x?.files[x?.files?.length - 1]?.link
                    ?.toLowerCase()
                    ?.includes('.mov')
                  : false;
              return (
                <Stack
                  key={index}
                  shadow={'md'}
                  borderRadius={'md'}
                  p={4}
                  spacing={5}
                  cursor="pointer"
                  onClick={() => handleVideoFeature(x)}
                >
                  <HStack
                    alignItems={'flex-start'}
                    justifyContent={'flex-start'}
                    spacing={3}
                  >
                    <Stack>
                      {x?.asignee?.length > 0 && (
                        <InputGroup>
                          <HStack ml="2" gap="0">
                            {[...new Set(x?.asignee)]?.sort().map((item, i) => {
                              const res = getUserByUid(
                                globalState?.users,
                                item
                              );

                              return (
                                <Box key={i}>
                                  <Tooltip
                                    label={res?.name || ''}
                                    aria-label="Name"
                                  >
                                    <Avatar
                                      size="sm"
                                      name={res?.name || ''}
                                      src={res?.name || ''}
                                    />
                                  </Tooltip>
                                </Box>
                              );
                            })}
                          </HStack>
                        </InputGroup>
                      )}
                    </Stack>
                    <Stack spacing={2}>
                      <Text fontWeight={500} textTransform="capitalize">
                        {x?.createdBy
                          ? getUserByUid(globalState?.users, x?.createdBy)?.name
                          : 'Anonymous'}{' '}
                        - {x?.title}
                      </Text>
                      <Text fontSize={'sm'} color={'gray.600'}>
                        Completed At:{' '}
                        {moment(x?.time_completed_at.seconds * 1000).format(
                          'ddd, DD-MM-YYYY HH:mm'
                        )}
                      </Text>

                      <Stack w={'300px'}>
                        {x?.files?.length > 0 ? (
                          <Stack shadow={'md'} borderRadius="md">
                            {typeFile === 'image' ? (
                              <Image
                                src={link}
                                borderRadius="md"
                                objectFit="contain"
                                w="full"
                                alt="www.google.com"
                              />
                            ) : typeFile === 'video' && mov ? (
                              <Stack alignItems={'center'} justify="center">
                                <FcVideoFile size={80} />
                              </Stack>
                            ) : typeFile === 'video' || typeFile === 'audio' ? (
                              <AspectRatio maxW="full" ratio={16 / 9}>
                                <iframe
                                  title="newFeature"
                                  allow="autoplay"
                                  src={link}
                                  allowFullScreen
                                />
                              </AspectRatio>
                            ) : (
                              <Stack alignItems={'center'} justify="center">
                                <FcFolder size={80} />
                              </Stack>
                            )}
                          </Stack>
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Stack>
                    <Spacer />
                    <Stack>
                      <Text
                        fontSize={'xx-small'}
                        textTransform="capitalize"
                        color={'gray.600'}
                      >
                        {moment(x?.time_completed_at.seconds * 1000).fromNow()}
                      </Text>
                    </Stack>
                  </HStack>
                </Stack>
              );
            })
          ) : (
            <Center>
              <Text fontSize={'lg'} fontWeight={500} color="gray.600">
                {t('noData')}
              </Text>
            </Center>
          )}
        </Stack>
        <Stack alignItems={'center'} justifyContent="center" py={5}>
          {data?.totalDoc < startIndex + itemsPerPage ? (
            <></>
          ) : (
            <Button
              colorScheme={'blue'}
              onClick={() => handleLoadMore()}
              size="sm"
            >
              {t('loadMore')}
            </Button>
          )}
        </Stack>
      </Stack>
      <NewFeatureDetailsModal
        isOpen={viewFeature.isOpen}
        onClose={() => {
          viewFeature.onClose();
          setData((prev) => ({ ...prev, selectedData: {} }));
        }}
        data={data?.selectedData}
      />
    </Stack>
  );
}

export default NewFeaturePage;
